import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {
  ROUTE_TIMESHEET_APPROVED_FOR_ADMIN_EDIT,
  ROUTE_TIMESHEET_APPROVED_FOR_EMPLOYEE_EDIT,
  ROUTE_TIMESHEET_APPROVED_FOR_MANAGER_EDIT,
  ROUTE_TIMESHEET_IN_PROGRESS_EDIT,
  ROUTE_TIMESHEET_IN_PROGRESS_FOR_ADMIN_EDIT,
  ROUTE_TIMESHEET_REJECTED_EDIT,
  ROUTE_TIMESHEET_REJECTED_FOR_ADMIN_EDIT,
  ROUTE_TIMESHEET_SUBMITTED_FOR_APPROVAL_FOR_ADMIN_EDIT,
  ROUTE_TIMESHEET_SUBMITTED_FOR_APPROVAL_FOR_MANAGER_EDIT,
  ROUTE_TIMESHEET_WITH_TAB_PARAM,
} from '../../RoutePathConstant';

const Timesheet = lazy(() =>
  import('../../../screens/Timesheet')
    .then(module => ({default: module.Timesheet})));

const ReviewTimesheetForAdmin = lazy(() =>
  import('../../../screens/Timesheet')
    .then(module => ({default: module.ReviewTimesheetForAdmin})));

const ReviewTimesheetForManager = lazy(() =>
  import('../../../screens/Timesheet')
    .then(module => ({default: module.ReviewTimesheetForManager})));

const RejectedTimesheet = lazy(() =>
  import('../../../screens/Timesheet')
    .then(module => ({default: module.RejectedTimesheet})));

const InProgressTimesheet = lazy(() =>
  import('../../../screens/Timesheet')
    .then(module => ({default: module.InProgressTimesheet})));

const ApprovedEmployee = lazy(() =>
  import('../../../screens/Timesheet')
    .then(module => ({default: module.ApprovedEmployee})));

const ApprovedManager = lazy(() =>
  import('../../../screens/Timesheet')
    .then(module => ({default: module.ApprovedManager})));

const ApprovedAdmin = lazy(() =>
  import('../../../screens/Timesheet')
    .then(module => ({default: module.ApprovedAdmin})));

const InProgressAdmin = lazy(() =>
  import('../../../screens/Timesheet')
    .then(module => ({default: module.InProgressAdmin})));

const RejectedAdmin = lazy(() =>
  import('../../../screens/Timesheet')
    .then(module => ({default: module.RejectedAdmin})));

const TimesheetNavigation = [
  {
    path: ROUTE_TIMESHEET_WITH_TAB_PARAM,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Timesheet />
      </Suspense>
    ),
  },
  {
    path: ROUTE_TIMESHEET_SUBMITTED_FOR_APPROVAL_FOR_ADMIN_EDIT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ReviewTimesheetForAdmin />
      </Suspense>
    ),
  },
  {
    path: ROUTE_TIMESHEET_SUBMITTED_FOR_APPROVAL_FOR_MANAGER_EDIT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ReviewTimesheetForManager />
      </Suspense>
    ),
  },
  {
    path: ROUTE_TIMESHEET_REJECTED_EDIT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <RejectedTimesheet />
      </Suspense>
    ),
  },
  {
    path: ROUTE_TIMESHEET_IN_PROGRESS_EDIT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <InProgressTimesheet />
      </Suspense>
    ),
  },
  {
    path: ROUTE_TIMESHEET_APPROVED_FOR_EMPLOYEE_EDIT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ApprovedEmployee />
      </Suspense>
    ),
  },
  {
    path: ROUTE_TIMESHEET_APPROVED_FOR_MANAGER_EDIT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ApprovedManager />
      </Suspense>
    ),
  },
  {
    path: ROUTE_TIMESHEET_APPROVED_FOR_ADMIN_EDIT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ApprovedAdmin />
      </Suspense>
    ),
  },
  {
    path: ROUTE_TIMESHEET_IN_PROGRESS_FOR_ADMIN_EDIT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <InProgressAdmin />
      </Suspense>
    ),
  },
  {
    path: ROUTE_TIMESHEET_REJECTED_FOR_ADMIN_EDIT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <RejectedAdmin />
      </Suspense>
    ),
  },
];

export default TimesheetNavigation;
