const CommonStatusEnum = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED',
  REVIEW : 'REVIEW',
  REVIEWED : 'REVIEWED',
  IN_PROGRESS: 'IN_PROGRESS',
  SUBMITTED: 'SUBMITTED',
  SUBMITTED_BY_SYSTEM: 'SUBMITTED_BY_SYSTEM',
  APPROVED_BY_SYSTEM: 'APPROVED_BY_SYSTEM',
  APPROVED_BY_ADMIN: 'APPROVED_BY_ADMIN',
  REJECTED_BY_ADMIN: 'REJECTED_BY_ADMIN',
  UNLOCKED: 'UNLOCKED',
  APPROVED_BY_MANAGER: 'APPROVED_BY_MANAGER',
  REJECTED_BY_MANAGER: 'REJECTED_BY_MANAGER',
  READ: 'READ',
  UNREAD: 'UNREAD',
};

export default CommonStatusEnum;
