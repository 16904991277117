import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest} from './rqHttpsUtils';
import {
  RQ_GET_TIMESHEET_ROWS_BY_TIMESHEET_ID_API,
  RQ_GET_TIMESHEET_ROWS_SEARCH_API,
  RQ_UPDATE_TIMESHEET_ROWS_BY_TIMESHEET_ID_API,
} from './constants';

export const RQUseGetTimesheetRowsByTimesheetIdData = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_timesheet_rows_by_timesheet_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {id, tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_TIMESHEET_ROWS_BY_TIMESHEET_ID_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
      );
    },
    gcTime: 0,
    ...options,
  });
};

export const RQPutTimesheetRowsByTimesheetId = async ({
  id,
  timesheetRows,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_TIMESHEET_ROWS_BY_TIMESHEET_ID_API,
    JSON.stringify({id, timesheetRows}),
    tokenData?.access_token,
    {id: id},
    signal,
  );
};

export const RQUseGetTimesheetRowsSearchData = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_timesheet_rows_search', payload],
    queryFn: async ({queryKey, signal}) => {
      const {tokenData, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_TIMESHEET_ROWS_SEARCH_API,
        null,
        tokenData?.access_token,
        null,
        signal,
        'application/json',
        'application/x-ndjson',
        filter,
      );
    },
    gcTime: 0,
    ...options,
  });
};
