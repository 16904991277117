export const RQ_GET_TIMESHEET_ROWS_BY_TIMESHEET_ID_API = {
  url: 'api/v1/timesheet/<%= id %>/timesheetrow/lookup',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_TIMESHEET_ROWS_BY_TIMESHEET_ID_API = {
  url: 'api/v1/timesheet/<%= id %>/update/withtimesheetrows',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_TIMESHEET_ROWS_SEARCH_API = {
  url: 'api/v1/timesheet/timesheetrow/search/all?',
  method: 'GET',
  auth: true,
  search: true,
};
