//Auth APIs
export const USER_LOGOUT_API = {
  url: 'api/v1/employee/<%= userId %>/logout',
  method: 'PUT',
  auth: true,
};

export const USER_PROFILE_API = {
  url: 'api/v1/<%= role %>/lookup/byemail/<%= email %>',
  method: 'GET',
  auth: true,
};

export const USER_PROFILE_UPDATE_API = {
  url: 'api/v1/employee/updateprofile/<%= userId %>',
  method: 'PUT',
  auth: true,
};
