export const RQ_USER_LOGIN_API = {
  url: 'api/v1/<%= role %>/login',
  method: 'POST',
  auth: false,
};

export const RQ_USER_FORGOT_PASSWORD_API = {
  url: 'api/v1/<%= role %>/forgot/password',
  method: 'PUT',
  auth: false,
};
