export const RQ_GET_SETTING_API = {
  url: 'api/v1/settings/lookup',
  method: 'GET',
  auth: true,
};

export const RQ_GET_SETTING_IRS_MILEAGE_RATE_ID_API = {
  url: 'api/v1/settings/mileagerateinfo/lookup',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_SETTING_IRS_MILEAGE_RATE_ID_API = {
  url: 'api/v1/settings/<%= id %>/mileagerate/update',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_SETTING_OTHER_EXPENSE_TYPE_ID_API = {
  url: 'api/v1/settings/expensetype/lookup/all',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_OTHER_EXPENSE_TYPE_API = {
  url: 'api/v1/settings/<%= settingsId %>/expensetype/<%= id %>/update/allfields',
  method: 'PUT',
  auth: true,
};

export const RQ_CREATE_EXPENSE_TYPE_API = {
  url: 'api/v1/settings/<%= id %>/add/expensetype',
  method: 'POST',
  auth: true,
};
