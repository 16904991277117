import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_SETTINGS} from '../../RoutePathConstant';

const Settings = lazy(() =>
  import('../../../screens/Settings')
    .then(module => ({default: module.Settings})));

const SettingsNavigation = [
  {
    path: ROUTE_SETTINGS,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Settings />
      </Suspense>
    ),
  },
];

export default SettingsNavigation;
