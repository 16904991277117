import React from 'react';
import {NavLink} from 'react-router-dom';

const MenuBuilder = menu => {
  const {key, path, label, icon} = menu;

  return {
    key: key,
    icon,
    title: label,
    label: (
      <NavLink
        to={`${path}`}
        className={({isActive, isPending}) =>
          isActive
            ? 'active'
            : isPending
              ? 'pending'
              : ''}
      >
        {label}
      </NavLink>
    ),
  };
};

export default MenuBuilder;
