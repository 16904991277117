import CommonStatusEnum from '@app/dto/enums/CommonStatusEnum';
import {
  ADMIN_COLOR,
  EMPLOYEE_COLOR,
  GRAY_300,
  GREEN_300,
  GREEN_600,
  MANAGER_COLOR,
  ORANGE_300,
  PRIMARY_900,
  RED_100,
  RED_300,
  RED_500,
  SUPERVISOR_COLOR,
  YELLOW_500,
} from '@app/style/GlobalColors';
import UserRoleEnum from '@app/dto/enums/UserRoleEnum';

export const getStatusColorByStatus = status => {
  switch (status) {
    case CommonStatusEnum.ACTIVE:
      return GREEN_300;
    case CommonStatusEnum.INACTIVE:
      return RED_300;
    case CommonStatusEnum.IN_PROGRESS:
      return YELLOW_500;
    case CommonStatusEnum.UNLOCKED:
      return GRAY_300;
    case CommonStatusEnum.SUBMITTED:
    case CommonStatusEnum.SUBMITTED_BY_SYSTEM:
      return ORANGE_300;
    case CommonStatusEnum.APPROVED_BY_ADMIN:
    case CommonStatusEnum.APPROVED_BY_MANAGER:
    case CommonStatusEnum.APPROVED_BY_SYSTEM:
      return GREEN_600;
    case CommonStatusEnum.REJECTED_BY_ADMIN:
    case CommonStatusEnum.REJECTED_BY_MANAGER:
      return RED_500;
    case CommonStatusEnum.DELETED:
      return RED_100;
    default:
      return PRIMARY_900;
  }
};

export const getStatusColorByRole = role => {
  switch (role) {
    case UserRoleEnum.ADMIN:
      return ADMIN_COLOR;
    case UserRoleEnum.MANAGER:
      return MANAGER_COLOR;
    case UserRoleEnum.EMPLOYEE:
      return EMPLOYEE_COLOR;
    case UserRoleEnum.SUPERVISOR:
      return SUPERVISOR_COLOR;
    default:
      return PRIMARY_900;
  }
};
