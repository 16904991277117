import {Button, Card, Space, Typography} from 'antd';
import {LinkOutlined} from '@ant-design/icons';
import './MainDashboardIndex.less';

const {Text} = Typography;

const MainDashboardIndex = () => {
  return (
    <Space direction={'horizontal'}>
      <Card>
        <Space direction={'horizontal'}>
          <LinkOutlined className={'main-dashboard-index'} />
          <Button
            type={'link'}
            href={'https://www.suyashconsulting.com/'}
            block
            target={'_blank'}
          >
            <Text className={'dashboard-index-text'}>
              Visit website
            </Text>
          </Button>
        </Space>
      </Card>
    </Space>
  );
};

export default MainDashboardIndex;
