export const RQ_GET_PAGEABLE_ALL_TIMESHEET_BY_STATUS_API = {
  url: 'api/v1/timesheet/pageable/lookup/bystatus/<%= status %>?page=<%= pageParam %>&',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_PAGEABLE_ALL_TIMESHEET_SUBMITTED_FOR_APPROVAL_FOR_ADMIN_API = {
  url: 'api/v1/timesheet/pageable/lookup/submittedforapproval/foradmin?page=<%= pageParam %>&',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_PAGEABLE_ALL_TIMESHEET_SUBMITTED_FOR_APPROVAL_FOR_MANAGER_API = {
  url: 'api/v1/timesheet/pageable/lookup/submittedforapproval/formanager/manager/<%= id %>?page=<%= pageParam %>&',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_PAGEABLE_ALL_TIMESHEET_REJECTED_FOR_EMPLOYEE_API = {
  url: 'api/v1/timesheet/pageable/lookup/rejectedbymanager/foremployee/<%= id %>?page=<%= pageParam %>&',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_TIMESHEET_BY_ID_API = {
  url: 'api/v1/timesheet/<%= id %>/lookup',
  method: 'GET',
  auth: true,
};

export const RQ_GET_TIMESHEET_BY_SEARCH_FOR_EMPLOYEE_API = {
  url: 'api/v1/timesheet/lookup/search/foremployee/<%= id %>?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_TIMESHEET_PAGEABLE_BY_SEARCH_FOR_EMPLOYEE_API = {
  url: 'api/v1/timesheet/pageable/lookup/search/foremployee/<%= id %>?page=<%= pageParam %>&',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_UPDATE_TIMESHEET_STATUS_AND_COMMENTS_API = {
  url: 'api/v1/timesheet/<%= id %>/admin/update/status/and/comments',
  method: 'PUT',
  auth: true,
};

export const RQ_UPDATE_MANAGER_TIMESHEET_STATUS_AND_COMMENTS_API = {
  url: 'api/v1/timesheet/<%= id %>/manager/update/status/and/comments',
  method: 'PUT',
  auth: true,
};

export const RQ_UPDATE_EMPLOYEE_TIMESHEET_STATUS_AND_COMMENTS_API = {
  url: 'api/v1/timesheet/<%= id %>/employee/update/status/and/comments',
  method: 'PUT',
  auth: true,
};

export const RQ_REGISTER_TIMESHEET_WITH_START_DATE_API = {
  url: 'api/v1/timesheet/register/withstartdate',
  method: 'POST',
  auth: true,
};

export const RQ_UPDATE_TIMESHEET_APPROVER_API = {
  url: 'api/v1/timesheet/<%= id %>/update/approver',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_PAGEABLE_TIMESHEET_APPROVED_FOR_EMPLOYEE_API = {
  url: 'api/v1/timesheet/pageable/lookup/approved/foremployee/<%= id %>?page=<%= pageParam %>&',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_PAGEABLE_TIMESHEET_APPROVED_FOR_MANAGER_API = {
  url: 'api/v1/timesheet/pageable/lookup/approved/formanager/<%= id %>?page=<%= pageParam %>&',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_PAGEABLE_TIMESHEET_APPROVED_FOR_ADMIN_API = {
  url: 'api/v1/timesheet/pageable/lookup/approved/foradmin?page=<%= pageParam %>&',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_TIMESHEET_COUNT_BY_EMPLOYEE_ID_AND_STATUS_LIST_API = {
  url: 'api/v1/timesheet/count/foremployee/<%= id %>/bystatus?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_TIMESHEET_COUNT_BY_MANAGER_ID_AND_STATUS_LIST_API = {
  url: 'api/v1/timesheet/count/formanager/<%= id %>/bystatus?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_TIMESHEET_COUNT_FOR_ADMIN_AND_STATUS_LIST_API = {
  url: 'api/v1/timesheet/count/foradmin/bystatus?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_EMPLOYEE_COUNT_BY_ROLE_AND_STATUS_LIST_API = {
  url: 'api/v1/employee/count/search/all?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_TIMESHEET_EXPORT_TO_EXEL_API = {
  url: 'api/v1/timesheet/<%= id %>/export/toexcel',
  method: 'GET',
  auth: true,
};

export const RQ_GET_TIMESHEET_EXPORT_TO_QUICKBOOK_API = {
  url: 'api/v1/timesheet/<%= id %>/export/toquickbook',
  method: 'GET',
  auth: true,
};
