import ExpenseReportStatusEnum from '../dto/enums/ExpenseReportStatusEnum';

export const expenseReportEditableByEmployee = status => {
  return (status === ExpenseReportStatusEnum.REJECTED_BY_MANAGER
        || status === ExpenseReportStatusEnum.REJECTED_BY_ADMIN
        || status === ExpenseReportStatusEnum.IN_PROGRESS
  );
};

export const expenseReportEditableBySupervisor = status => {
  return (status === ExpenseReportStatusEnum.SUBMITTED
        || status === ExpenseReportStatusEnum.SUBMITTED_BY_SYSTEM
  );
};

export const expenseReportEditableByAdmin = status => {
  return (status === ExpenseReportStatusEnum.SUBMITTED
        || status === ExpenseReportStatusEnum.SUBMITTED_BY_SYSTEM
        || status === ExpenseReportStatusEnum.REJECTED_BY_ADMIN
        || status === ExpenseReportStatusEnum.IN_PROGRESS
        || status === ExpenseReportStatusEnum.REJECTED_BY_MANAGER
  );
};

export const totalExpenseData = expensesRows => {
  let totalMiles = 0;
  let totalMileageAmount = 0;
  let totalOtherExpensesAmount = 0;

  if (expensesRows && Array.isArray(expensesRows)) {
    expensesRows.forEach(expenseRow => {

      if (expenseRow) {
        const {expenseMileage = 0, expenseMileageRate = 0, expenseAmount = 0} = expenseRow;
        const mileageAmount = expenseMileage * expenseMileageRate;

        totalMiles = totalMiles + parseFloat('' + expenseMileage);
        totalMileageAmount = totalMileageAmount + mileageAmount;
        totalOtherExpensesAmount = totalOtherExpensesAmount + parseFloat('' + expenseAmount);
      }
    });
  }
  return {totalMiles, totalMileageAmount, totalOtherExpensesAmount};
};

export const isExpenseDateEmpty = expenseRows => !(expenseRows.every(row => !!row.expenseDate));
