import {useInfiniteQuery, useQuery} from '@tanstack/react-query';
import {rqCreateRequest} from './rqHttpsUtils';
import {
  RQ_CREATE_COST_CODE_BY_JOB_CODE_API,
  RQ_GET_ALL_JOB_CODES_API,
  RQ_GET_ALL_JOB_CODES_BY_SEARCH_API,
  RQ_GET_ALL_JOB_CODES_BY_WATCHER_API,
  RQ_GET_COST_CODE_BY_JOB_CODE_ID_API,
  RQ_GET_JOB_CODE_BY_ID_API,
  RQ_GET_PAGEABLE_ALL_JOB_CODES_API,
  RQ_GET_PAGEABLE_COST_CODE_BY_JOB_CODE_ID_API,
  RQ_REGISTER_JOB_CODE_API,
  RQ_UPDATE_COST_CODE_STATUS_API,
  RQ_UPDATE_JOB_CODE_DESCRIPTION_STATUS_AND_API,
  RQ_UPDATE_JOB_CODE_STATUS_API,
} from './constants';

export const RQUseGetPageableAllJobCodesData = (payload, options = {}) => {
  return useInfiniteQuery({
    queryKey: ['rq_get_pageable_all_job_codes', payload],
    queryFn: async ({queryKey, signal, pageParam = 0}) => {
      const {tokenData, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_PAGEABLE_ALL_JOB_CODES_API,
        null,
        tokenData?.access_token,
        {pageParam},
        signal,
        'application/json',
        'application/x-ndjson',
        filter,
      );
    },
    initialPageParam: 0,
    refetchInterval: 30 * 1000,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
    ...options,
  });
};

export const RQUseGetAllJobCodesData = payload => {
  return useQuery({
    queryKey:['rq_get_all_job_codes', payload],
    queryFn: async ({queryKey, signal}) => {
      const {tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_ALL_JOB_CODES_API,
        null,
        tokenData?.access_token,
        null,
        signal,
      );
    },
  });
};

export const RQUseGetAllJobCodesDataBySearch = payload => {
  return useQuery({
    queryKey: ['rq_get_all_job_codes_by_search', payload],
    queryFn: async ({queryKey, signal}) => {
      const {tokenData, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_ALL_JOB_CODES_BY_SEARCH_API,
        null,
        tokenData?.access_token,
        null,
        signal,
        'application/json',
        'application/json',
        filter,
      );
    },
  });
};

export const RQPostJobCodeRegister = async ({
  jobcode,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REGISTER_JOB_CODE_API,
    JSON.stringify({...jobcode}),
    tokenData?.access_token,
    null,
    signal,
  );
};

export const RQPostCreateCostCodeByJobCode = async ({
  costCode,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_COST_CODE_BY_JOB_CODE_API,
    JSON.stringify({...costCode}),
    tokenData?.access_token,
    {id: costCode.jobCodeId},
    signal,
  );
};

export const RQUseGetJobCodeByIdData = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_job_code_by_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {id, tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_JOB_CODE_BY_ID_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
      );
    },
    gcTime: 0,
    ...options,
  });
};

export const RQPutJobCodeUpdateStatus = async ({
  jobCode,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_JOB_CODE_STATUS_API,
    JSON.stringify({...jobCode}),
    tokenData?.access_token,
    {id: jobCode.id},
    signal,
  );
};

export const RQPutJobCodeUpdateDescriptionAndStatus = async ({
  jobCode,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_JOB_CODE_DESCRIPTION_STATUS_AND_API,
    JSON.stringify({...jobCode}),
    tokenData?.access_token,
    {id: jobCode.id},
    signal,
  );

};
export const RQPutCostCodeUpdate = async ({
  costCode,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_COST_CODE_STATUS_API,
    JSON.stringify({...costCode}),
    tokenData?.access_token,
    {jobCodeId: costCode.jobCodeId, id: costCode.id},
    signal,
  );
};

export const RQUseGetCostCodeByJobCodeIdData = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_cost_code_by_job_code_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {id, tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_COST_CODE_BY_JOB_CODE_ID_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
      );
    },
    gcTime: 2 * 60 * 1000, // 2 min
    ...options,
  },
  );
};

export const RQUseGetPageableCostCodeByJobCodeIdData = (payload, options = {}) => {
  return useInfiniteQuery({
    queryKey: ['rq_get_pageable_cost_code_by_job_code_id', payload],
    queryFn: async ({queryKey, signal, pageParam = 0}) => {
      const {id, tokenData, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_PAGEABLE_COST_CODE_BY_JOB_CODE_ID_API,
        null,
        tokenData?.access_token,
        {id, pageParam},
        signal,
        'application/json',
        'application/x-ndjson',
        filter,
      );
    },
    initialPageParam: 0,
    refetchInterval: 30 * 1000,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
    ...options,
  });
};

export const RQUseGetAllJobCodeByWatcher = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_all_job_code_by_watcher', payload],
    queryFn: async ({queryKey, signal}) => {
      const {watcherId, tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_ALL_JOB_CODES_BY_WATCHER_API,
        null,
        tokenData?.access_token,
        {watcherId},
        signal,
      );
    },
    gcTime: 0,
    ...options,
  },
  );
};
