import {
  RQ_CREATE_EXPENSE_REPORT_API,
  RQ_GET_ALL_EXPENSE_REPORT_LIST_PAGEABLE_SEARCH_API,
  RQ_GET_EXPENSE_REPORT_BY_ID_API,
  RQ_GET_EXPENSE_REPORT_BY_JOB_CODE_BY_PERIOD_FROM_API,
  RQ_GET_EXPENSE_REPORT_DATA_FOR_PDF_API,
  RQ_GET_EXPENSE_REPORT_EXPORT_TO_EXEL_API,
  RQ_GET_EXPENSE_REPORT_ROWS_FOR_EMPLOYEE_API,
  RQ_GET_EXPENSE_REPORT_ROWS_FOR_SUYASH_API,
  RQ_GET_JOB_CODE_BY_PERIOD_FROM_API,
  RQ_GET_SEARCH_EXPENSE_REPORT_EXPORT_TO_EXEL_API,
  RQ_REVIEW_EXPENSE_REPORT_BY_ADMIN_API,
  RQ_REVIEW_EXPENSE_REPORT_BY_SUPERVISOR_API,
  RQ_SUBMIT_EXPENSE_REPORT_BY_EMPLOYEE_API,
  RQ_UPDATE_EXPENSE_REPORT_ROWS_FOR_EMPLOYEE_API,
  RQ_UPDATE_EXPENSE_REPORT_ROWS_FOR_SUYASH_API,
} from './constants';
import {rqCreateRequest} from './rqHttpsUtils';
import {useInfiniteQuery, useQuery} from '@tanstack/react-query';

export const RQPostCreateExpenseReport = async ({
  expenseReport,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_EXPENSE_REPORT_API,
    JSON.stringify({...expenseReport}),
    tokenData?.access_token,
    null,
    signal,
  );
};

export const RQUseGetAllExpenseReportDataPageableSearch = payload => {
  return useInfiniteQuery({
    queryKey: ['rq_get_all_expense_report_data_pageable_search', payload],
    queryFn: async ({queryKey, signal, pageParam = 0}) => {
      const {tokenData, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_ALL_EXPENSE_REPORT_LIST_PAGEABLE_SEARCH_API,
        null,
        tokenData?.access_token,
        {pageParam},
        signal,
        'application/json',
        'application/json',
        filter,
      );
    },
    initialPageParam: 0,
    gcTime: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
  });
};

export const RQUseGetExpenseReportByIdData = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_expense_report_by_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {id, tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EXPENSE_REPORT_BY_ID_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
      );
    },
    gcTime: 2 * 60 * 1000, // 2 min
    ...options,
  });
};

export const RQUseGetExpenseReportRowsForEmployeeData = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_expense_report_rows_for_employee', payload],
    queryFn: async ({queryKey, signal}) => {
      const {id, tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EXPENSE_REPORT_ROWS_FOR_EMPLOYEE_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
      );
    },
    gcTime: 2 * 60 * 1000, // 2 min
    ...options,
  });
};

export const RQUseGetExpenseReportRowsForSuyashData = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_expense_report_rows_for_suyash', payload],
    queryFn: async ({queryKey, signal}) => {
      const {id, tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EXPENSE_REPORT_ROWS_FOR_SUYASH_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
      );
    },
    gcTime: 2 * 60 * 1000, // 2 min
    ...options,
  });
};

export const RQPutExpenseReportRowsForEmployee = async ({
  id,
  expenseRowsForEmployee,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_EXPENSE_REPORT_ROWS_FOR_EMPLOYEE_API,
    JSON.stringify({id, expenseRowsForEmployee}),
    tokenData?.access_token,
    {id: id},
    signal,
  );
};

export const RQPutExpenseReportRowsForSuyash = async ({
  id,
  expenseRowsForSuyash,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_EXPENSE_REPORT_ROWS_FOR_SUYASH_API,
    JSON.stringify({id, expenseRowsForSuyash}),
    tokenData?.access_token,
    {id: id},
    signal,
  );
};

export const RQPutSubmitExpenseReportByEmployee = async ({
  id,
  expenseReportSubmittedByEmployee,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_SUBMIT_EXPENSE_REPORT_BY_EMPLOYEE_API,
    JSON.stringify({...expenseReportSubmittedByEmployee}),
    tokenData?.access_token,
    {id: id},
    signal,
  );
};

export const RQPutReviewExpenseReportBySupervisor = async ({
  id,
  expenseReportReviewedBySupervisor,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REVIEW_EXPENSE_REPORT_BY_SUPERVISOR_API,
    JSON.stringify({...expenseReportReviewedBySupervisor}),
    tokenData?.access_token,
    {id: id},
    signal,
  );
};

export const RQPutReviewExpenseReportByAdmin = async ({
  id,
  expenseReportReviewedByAdmin,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REVIEW_EXPENSE_REPORT_BY_ADMIN_API,
    JSON.stringify({...expenseReportReviewedByAdmin}),
    tokenData?.access_token,
    {id: id},
    signal,
  );
};

export const RQUseGetExpenseReportExportToExcel = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_expense_report_export_to_excel', payload],
    queryFn: async ({queryKey, signal}) => {
      const {id, tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EXPENSE_REPORT_EXPORT_TO_EXEL_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
        'application/json',
        'application/json',
      );
    },
    gcTime: 2 * 60 * 1000, // 2 min
    ...options,
  });
};

export const RQUseGetExpenseReportDataForPdf = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_expense_report_data_for_pdf', payload],
    queryFn: async ({queryKey, signal}) => {
      const {id, tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EXPENSE_REPORT_DATA_FOR_PDF_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
        'application/json',
        'application/json',
      );
    },
    gcTime: 2 * 60 * 1000, // 2 min
    ...options,
  });
};

export const RQUseGetJobCodeByPeriodFrom = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_job_code_by_period_from', payload],
    queryFn: async ({queryKey, signal}) => {
      const {tokenData, periodFrom} = queryKey[1];
      return rqCreateRequest(
        RQ_GET_JOB_CODE_BY_PERIOD_FROM_API,
        null,
        tokenData?.access_token,
        {periodFrom},
        signal,
      );
    },
    gcTime: 2 * 60 * 1000, // 2 min
    ...options,
  });
};

export const RQUseGetExpenseReportByJobCodeByPeriodFrom = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_job_code_by_period_from_job_code', payload],
    queryFn: async ({queryKey, signal}) => {
      const {tokenData, filter} = queryKey[1];
      return rqCreateRequest(
        RQ_GET_EXPENSE_REPORT_BY_JOB_CODE_BY_PERIOD_FROM_API,
        null,
        tokenData?.access_token,
        null,
        signal,
        'application/json',
        'application/json',
        filter,
      );
    },
    gcTime: 2 * 60 * 1000, // 2 min
    ...options,
  });
};

export const RQUseGetSearchExpenseReportExportToExcel = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_expense_report_export_to_excel', payload],
    queryFn: async ({queryKey, signal}) => {
      const {jobCodeId, periodFrom, tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_SEARCH_EXPENSE_REPORT_EXPORT_TO_EXEL_API,
        null,
        tokenData?.access_token,
        {jobCodeId, periodFrom},
        signal,
        'application/json',
        'application/json',
      );
    },
    gcTime: 2 * 60 * 1000, // 2 min
    ...options,
  });
};
