import {createReducer} from './reducerUtils';
import {assign} from 'lodash';
import {
  RESET_DATA,
  RESET_SETTINGS_DATA,
  UPDATE_DATE_FORMAT_SUCCESSFUL,
  UPDATE_TIMESHEET_DELAY_IN_HOURS_SUCCESSFUL,
  UPDATE_TIMEZONE_SUCCESSFUL,
} from '../actionConstants';

const initialState = {
  dateFormat: 'MM/DD/YYYY',
  timezone: 'EST',
  timesheetDelayInHours: 8,
};

const updateDateFormatSuccessful = (state = initialState, payload) => {
  return assign({}, state, {
    dateFormat: payload.dateFormat,
  });
};

const updateTimezoneSuccessful = (state = initialState, payload) => {
  return assign({}, state, {
    timezone: payload.timeZone,
  });
};

const updateTimesheetDelayInHourSuccessful = (state = initialState, payload) => {
  return assign({}, state, {
    timesheetDelayInHours: payload.timesheetDelayInHours,
  });
};

const resetSettingsData = (state = initialState) => {
  return initialState;
};

export default createReducer(initialState, {
  [RESET_DATA]: resetSettingsData,
  [RESET_SETTINGS_DATA]: resetSettingsData,
  [UPDATE_DATE_FORMAT_SUCCESSFUL]: updateDateFormatSuccessful,
  [UPDATE_TIMEZONE_SUCCESSFUL]: updateTimezoneSuccessful,
  [UPDATE_TIMESHEET_DELAY_IN_HOURS_SUCCESSFUL]: updateTimesheetDelayInHourSuccessful,
});
