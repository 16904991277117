import {DashboardNavigation} from './screensNavigation/Dashboard';
import {createBrowserRouter} from 'react-router-dom';
import {MainHome} from '../screensComponents/HomeScreen/MainHome';
import {ErrorPage} from '../components/common/ErrorPage';
import {IndexNavigation} from './screensNavigation/Index/index';
import React from 'react';
import {PublicRoute} from './PublicRoute';
import {UsersNavigation} from './screensNavigation/Users';
import {TimesheetConfigNavigation} from './screensNavigation/TimesheetConfig';
import {SettingsNavigation} from './screensNavigation/Settings';
import {TimesheetNavigation} from './screensNavigation/Timesheet';
import {LoginNavigation} from './screensNavigation/Login';
import {DashboardIndexNavigation} from './screensNavigation/DashboardIndex';
import {ROUTE_BASE_NAME} from './RoutePathConstant';
import {ProfileNavigation} from './screensNavigation/Profile';
import {NotificationNavigation} from './screensNavigation/notification';
import {ExpenseReportNavigation} from './screensNavigation/ExpenseReport';
import {WatchNavigation} from '@app/navigations/screensNavigation/Watch';
import ProjectReportNavigation from '@app/navigations/screensNavigation/ProjectReport/ProjectReportNavigation';

const AuthRoute = [
  ...DashboardNavigation,
  ...TimesheetNavigation,
  ...ProfileNavigation,
  ...UsersNavigation,
  ...TimesheetConfigNavigation,
  ...SettingsNavigation,
  ...NotificationNavigation,
  ...ExpenseReportNavigation,
  ...WatchNavigation,
  ...ProjectReportNavigation,
];

export const AppRouter = createBrowserRouter([
  IndexNavigation,
  ...LoginNavigation,
  {
    path: '',
    element: <MainHome />,
    errorElement: <ErrorPage />,
    children: [
      DashboardIndexNavigation,
      ...AuthRoute,
      ...PublicRoute,
    ],
  },
], {
  basename: ROUTE_BASE_NAME,
});
