import {isEmpty} from 'lodash';
import UserRoleEnum from '../dto/enums/UserRoleEnum';

export default class AuthUtil {
  static isAdmin = role => {
    if (isEmpty(role)) {
      return false;
    }
    return role?.toLowerCase() === UserRoleEnum.ADMIN.toLowerCase();
  };

  static isManager = role => {
    if (isEmpty(role)) {
      return false;
    }
    return role?.toLowerCase() === UserRoleEnum.MANAGER.toLowerCase();
  };

  static getApiRole = role => {
    if (AuthUtil.isAdmin(role)) {
      return 'admin';
    } else {
      return 'employee';
    }
  };

  static getEmployeeRoles = () => [UserRoleEnum.MANAGER, UserRoleEnum.EMPLOYEE, UserRoleEnum.SUPERVISOR];
}
