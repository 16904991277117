export const RQ_CREATE_EXPENSE_REPORT_API = {
  url: 'api/v1/expensereport/add',
  method: 'POST',
  auth: true,
};

export const RQ_GET_ALL_EXPENSE_REPORT_LIST_PAGEABLE_SEARCH_API = {
  url: 'api/v1/expensereport/pageable/lookup/search/all?page=<%= pageParam %>&',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_EXPENSE_REPORT_BY_ID_API = {
  url: 'api/v1/expensereport/<%= id %>/lookup',
  method: 'GET',
  auth: true,
};

export const RQ_GET_EXPENSE_REPORT_ROWS_FOR_EMPLOYEE_API = {
  url: 'api/v1/expensereport/<%= id %>/expenserows/lookup/foremployee',
  method: 'GET',
  auth: true,
};

export const RQ_GET_EXPENSE_REPORT_ROWS_FOR_SUYASH_API = {
  url: 'api/v1/expensereport/<%= id %>/expenserows/lookup/forsuyash',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_EXPENSE_REPORT_ROWS_FOR_EMPLOYEE_API = {
  url: 'api/v1/expensereport/<%= id %>/update/withexpenserows/foremployee',
  method: 'PUT',
  auth: true,
};

export const RQ_UPDATE_EXPENSE_REPORT_ROWS_FOR_SUYASH_API = {
  url: 'api/v1/expensereport/<%= id %>/update/withexpenserows/forsuyash',
  method: 'PUT',
  auth: true,
};

export const RQ_SUBMIT_EXPENSE_REPORT_BY_EMPLOYEE_API = {
  url: 'api/v1/expensereport/<%= id %>/submit/byemployee',
  method: 'PUT',
  auth: true,
};

export const RQ_REVIEW_EXPENSE_REPORT_BY_SUPERVISOR_API = {
  url: 'api/v1/expensereport/<%= id %>/review/bysupervisor',
  method: 'PUT',
  auth: true,
};

export const RQ_REVIEW_EXPENSE_REPORT_BY_ADMIN_API = {
  url: 'api/v1/expensereport/<%= id %>/review/byadmin',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_EXPENSE_REPORT_EXPORT_TO_EXEL_API = {
  url: 'api/v1/expensereport/<%= id %>/export/toexcel',
  method: 'GET',
  auth: true,
};

export const RQ_GET_EXPENSE_REPORT_DATA_FOR_PDF_API = {
  url: 'api/v1/expensereport/<%= id %>/export/topdf',
  method: 'GET',
  auth: true,
};

export const RQ_GET_JOB_CODE_BY_PERIOD_FROM_API = {
  url: 'api/v1/expensereport/byperiodfrom/jobcode/lookup?periodFrom=<%= periodFrom %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_EXPENSE_REPORT_BY_JOB_CODE_BY_PERIOD_FROM_API = {
  url: 'api/v1/expensereport/lookup/byjobcode/byperiodfrom?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_SEARCH_EXPENSE_REPORT_EXPORT_TO_EXEL_API = {
  url: 'api/v1/expensereport/jobcode/periodfrom/export/toexcel?jobCodeId=<%= jobCodeId %>&periodFrom=<%= periodFrom %>',
  method: 'GET',
  auth: true,
};
