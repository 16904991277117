import {get, isEmpty, lowerCase} from 'lodash';
import {createRequest} from './httpUtils';
import {USER_LOGOUT_API, USER_PROFILE_API, USER_PROFILE_UPDATE_API} from './constants';
import AuthUtil from '../../../security/AuthUtil';

export const logoutUser = payload => {
  if (isEmpty(get(payload, 'user.id'))) {
    return invalidLogout(payload);
  }
  return createRequest(
    USER_LOGOUT_API,
    {},
    payload.tokenData?.access_token,
    {
      userId: get(payload, 'user.id', 'INVALID_USER'),
      role: lowerCase(get(payload, 'user.role')),
    },
  );
};

export const invalidLogout = payload => {
  return createRequest(
    USER_LOGOUT_API,
    {},
    payload?.tokenData?.access_token,
    {
      userId: 'invalid',
      role: lowerCase(get(payload, 'user.role')),
    },
  );
};

export const getUserProfileAPI = payload => {
  return createRequest(
    USER_PROFILE_API,
    null,
    payload.tokenData?.access_token,
    {email: payload?.user?.email, role: AuthUtil.getApiRole(payload?.user?.role)},
  );
};

export const updateUserProfileAPI = payload => {
  return createRequest(
    USER_PROFILE_UPDATE_API,
    {...payload.user},
    payload.tokenData?.access_token,
    {userId: payload.user.id, role: lowerCase(payload?.user?.role)},
  );
};
