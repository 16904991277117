export const RQ_GET_PAGEABLE_ALL_PROJECT_API = {
  url:'api/v1/project/pageable/lookup/all?page=<%= pageParam %>&',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_REGISTER_PROJECT_API = {
  url: 'api/v1/project/register',
  method: 'POST',
  auth: true,
};

export const RQ_GET_PROJECT_BY_ID_API = {
  url:'api/v1/project/<%= id %>/lookup',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_ALL_FIELDS_BY_PROJECT_API = {
  url: 'api/v1/project/<%= id %>/update/allfields',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_PROJECT_LIST_API = {
  url:'api/v1/project/lookup/all/active',
  method: 'GET',
  auth: true,
};
