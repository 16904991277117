import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest} from './rqHttpsUtils';
import {
  RQ_CREATE_EXPENSE_TYPE_API,
  RQ_GET_SETTING_API,
  RQ_GET_SETTING_IRS_MILEAGE_RATE_ID_API,
  RQ_GET_SETTING_OTHER_EXPENSE_TYPE_ID_API,
  RQ_UPDATE_OTHER_EXPENSE_TYPE_API,
  RQ_UPDATE_SETTING_IRS_MILEAGE_RATE_ID_API,
} from './constants';

export const RQUseGetSetting = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_setting_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_SETTING_API,
        null,
        tokenData?.access_token,
        null,
        signal,
      );
    },
    gcTime: 0,
    ...options,
  });
};

export const RQUseGetSettingIRSMileageRateData = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_setting_irs_mileage_rate_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_SETTING_IRS_MILEAGE_RATE_ID_API,
        null,
        tokenData?.access_token,
        null,
        signal,
      );
    },
    gcTime: 0,
    ...options,
  });
};

export const RQUseUpdateSettingIRSMileageRate = async ({
  mileageRateInfo,
  tokenData,
  signal,
}) => {

  return rqCreateRequest(
    RQ_UPDATE_SETTING_IRS_MILEAGE_RATE_ID_API,
    JSON.stringify({...mileageRateInfo}),
    tokenData?.access_token,
    {id: mileageRateInfo?.settingsId},
    signal,
  );
};

export const RQUseGetSettingOtherExpenseTypeData = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_setting_other_expense_type_data', payload],
    queryFn: async ({queryKey, signal}) => {

      const {tokenData} = queryKey[1];
      return rqCreateRequest(
        RQ_GET_SETTING_OTHER_EXPENSE_TYPE_ID_API,
        null,
        tokenData?.access_token,
        null,
        signal,
        'application/json',
        'application/x-ndjson',
      );
    },
    gcTime: 0,
    ...options,
  });
};

export const RQUpdateOtherExpenseType = async ({
  expenseType,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_OTHER_EXPENSE_TYPE_API,
    JSON.stringify({...expenseType}),
    tokenData?.access_token,
    {settingsId: expenseType.settingsId, id: expenseType.id},
    signal,
  );
};

export const RQPostCreateExpenseType = async ({
  expenseType,
  tokenData,
  settingsId,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_EXPENSE_TYPE_API,
    JSON.stringify({...expenseType}),
    tokenData?.access_token,
    {id: settingsId},
    signal,
  );
};
