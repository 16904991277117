import React, {Suspense, lazy} from 'react';
import {
  ROUTE_EXPENSE_REPORT_CREATE_FOR_EMPLOYEE,
  ROUTE_EXPENSE_REPORT_CREATE_FOR_MANAGER,
  ROUTE_EXPENSE_REPORT_EDIT_WITH_SELECTED_TAB,
  ROUTE_EXPENSE_REPORT_EDIT_WITH_SELECTED_TAB_FOR_ADMIN,
  ROUTE_EXPENSE_REPORT_FOR_ADMIN,
  ROUTE_EXPENSE_REPORT_FOR_EMPLOYEE,
  ROUTE_EXPENSE_REPORT_WITH_TAB_PARAM,
  ROUTE_EXPENSE_REPORT_WITH_TAB_PARAM_FOR_ADMIN,
} from '../../RoutePathConstant';
import {authLoader} from '../../loader';
import {Spin} from 'antd';

const AdminExpenseReport = lazy(() =>
  import('../../../screens/ExpenseReport')
    .then(module => ({default: module.AdminExpenseReport})));

const ManagerExpenseReport = lazy(() =>
  import('../../../screens/ExpenseReport')
    .then(module => ({default: module.ManagerExpenseReport})));

const EmployeeExpenseReport = lazy(() =>
  import('../../../screens/ExpenseReport')
    .then(module => ({default: module.EmployeeExpenseReport})));

const CreateEmployeeExpenseReport = lazy(() =>
  import('../../../screens/ExpenseReport')
    .then(module => ({default: module.CreateEmployeeExpenseReport})));

const EditExpenseReport = lazy(() =>
  import('../../../screens/ExpenseReport')
    .then(module => ({default: module.EditExpenseReport})));

const ExpenseReportNavigation = [
  {
    path: ROUTE_EXPENSE_REPORT_FOR_EMPLOYEE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EmployeeExpenseReport />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EXPENSE_REPORT_CREATE_FOR_EMPLOYEE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CreateEmployeeExpenseReport />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EXPENSE_REPORT_EDIT_WITH_SELECTED_TAB,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EditExpenseReport />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EXPENSE_REPORT_WITH_TAB_PARAM,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ManagerExpenseReport />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EXPENSE_REPORT_CREATE_FOR_MANAGER,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CreateEmployeeExpenseReport />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EXPENSE_REPORT_FOR_ADMIN,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AdminExpenseReport />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EXPENSE_REPORT_WITH_TAB_PARAM_FOR_ADMIN,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AdminExpenseReport />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EXPENSE_REPORT_EDIT_WITH_SELECTED_TAB_FOR_ADMIN,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EditExpenseReport />
      </Suspense>
    ),
  },
];

export default ExpenseReportNavigation;
