import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest} from './rqHttpsUtils';
import {
  RQ_GET_ADMIN_BY_ID_API,
  RQ_GET_ALL_USERS_LIST_API,
  RQ_REGISTER_ADMIN_ALL_FIELDS_API,
  RQ_UPDATE_ADMIN_PROFILE_FIELD_API,
} from './constants';

export const RQUseGetAdminByIdData = payload => {
  return useQuery({
    queryKey: ['rq_get_admin_by_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {id, tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_ADMIN_BY_ID_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
      );
    },
    gcTime: 0,
  },
  );
};

export const RQPutUpdateAdminProfileField = async ({
  admin,
  tokenData,
  id,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_ADMIN_PROFILE_FIELD_API,
    JSON.stringify({...admin}),
    tokenData?.access_token,
    {id},
    signal,
  );
};

export const RQUseGetAllUsersListData = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_all_users_list_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_ALL_USERS_LIST_API,
        null,
        tokenData?.access_token,
        null,
        signal,
      );
    },
    gcTime: 2 * 60 * 1000, // 2 min
    ...options,
  });
};

export const RQPostAdminRegisterAllFields = async ({
  employee,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REGISTER_ADMIN_ALL_FIELDS_API,
    JSON.stringify({...employee}),
    tokenData?.access_token,
    null,
    signal,
  );
};
