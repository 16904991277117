import React from 'react';
import {NotificationFilled} from '@ant-design/icons';
import {Avatar, Badge} from 'antd';
import {GRAY_300, WHITE_COLOR} from '@app/style/GlobalColors';
import PropTypes from 'prop-types';
import {selectAuth} from '@app/redux/selectors';
import {connect} from 'react-redux';
import {RQUseGetNotificationInAppCountByStatus} from '@app/reactQuery/apis';
import NotificationStatusEnum from '@app/dto/enums/NotificationStatusEnum';

const NotificationBadge = ({auth}) => {

  const {data = {count: 0}} = RQUseGetNotificationInAppCountByStatus({
    tokenData: auth?.tokenData,
    filter: {
      userId: auth?.user?.id,
      statusList: [NotificationStatusEnum.UNREAD],
    },
  }, {
    enabled: !!auth?.user?.id,
    refetchInterval: 10000,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
  });

  return (
    <Badge count={data?.count} size={'small'} offset={[2, 2]}>
      <Avatar style={{backgroundColor: GRAY_300}} shape={'square'} size={32}  icon={<NotificationFilled color={WHITE_COLOR} />} />
    </Badge>
  );
};

NotificationBadge.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(NotificationBadge);
