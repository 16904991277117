export const RQ_GET_NOTIFICATION_IN_APP_COUNT_BY_STATUS_LIST_API = {
  url: 'api/v1/notification/inapp/count/bystatus?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_NOTIFICATION_IN_APP_PAGEABLE_BY_SEARCH_API = {
  url: 'api/v1/notification/inapp/pageable/lookup/search/all?page=<%= pageParam %>&',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_UPDATE_NOTIFICATION_IN_APP_STATUS_API = {
  url: 'api/v1/notification/inapp/<%= id %>/update/status',
  method: 'PUT',
  auth: true,
};
