export const RQ_GET_PAGEABLE_ALL_EMPLOYEE_API = {
  url: 'api/v1/employee/pageable/lookup/search/all?page=<%= pageParam %>&',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_EMPLOYEE_BY_ID_API = {
  url: 'api/v1/employee/<%= id %>/lookup',
  method: 'GET',
  auth: true,
};

export const RQ_GET_EMPLOYEE_BY_ID_WITH_MANAGER_DETAILS_API = {
  url: 'api/v1/employee/<%= id %>/lookup/withmanager',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_EMPLOYEE_ALL_FIELDS_API = {
  url: 'api/v1/employee/<%= employeeId %>/update/allfields',
  method: 'PUT',
  auth: true,
};

export const RQ_REGISTER_EMPLOYEE_ALL_FIELDS_API = {
  url: 'api/v1/employee/register',
  method: 'POST',
  auth: true,
};

export const RQ_GET_EMPLOYEE_LIST_BY_MANAGER_ID_API = {
  url: 'api/v1/employee/lookup/formanager/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_EMPLOYEE_PROFILE_FIELD_API = {
  url: 'api/v1/employee/<%= id %>/update/profile',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_ALL_EMPLOYEE_LIST_API = {
  url: 'api/v1/employee/lookup/search/all',
  method: 'GET',
  auth: true,
};

export const RQ_GET_MANAGER_AND_ADMIN_LIST_API = {
  url: 'api/v1/employee/search/all/managerandadmin',
  method: 'GET',
  auth: true,
};
export const RQ_GET_EMPLOYEE_LOOKUP_ALL_ACTIVE_SUPERVISOR_API = {
  url: 'api/v1/employee/lookup/all/active/supervisor',
  method: 'GET',
  auth: true,
};
