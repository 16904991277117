export const RQ_GET_ADMIN_BY_ID_API = {
  url: 'api/v1/admin/<%= id %>/lookup',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_ADMIN_PROFILE_FIELD_API = {
  url: 'api/v1/admin/<%= id %>/update/profile',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_ALL_USERS_LIST_API = {
  url: 'api/v1/admin/lookup/allusers/search/all',
  method: 'GET',
  auth: true,
};

export const RQ_REGISTER_ADMIN_ALL_FIELDS_API = {
  url: 'api/v1/admin/register',
  method: 'POST',
  auth: true,
};
