import React, {useEffect, useState} from 'react';
import {MenuBuilder} from './MenuBuilder';
import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import {Col, Image, Layout, Menu} from 'antd';
import * as PropTypes from 'prop-types';
import {Link, useLocation} from 'react-router-dom';
import {ROUTE_BASE_ROOT, ROUTE_HOME} from '@app/navigations/RoutePathConstant';
import {selectAuth} from '@app/redux/selectors';
import {connect} from 'react-redux';
import AuthUtil from '@app/security/AuthUtil';
import UserRoleEnum from '@app/dto/enums/UserRoleEnum';
import SuyashCLLCImg from '@app/assets/images/logo/suyashcllc.png';
import {EmployeeMenuList} from './MenuList/EmployeeMenuList';
import {ManagerMenuList} from './MenuList/ManagerMenuList';
import {LogoutMenuList} from './MenuList/LogoutMenuList';
import {AdminMenuGroup, AdminTopSideMenuList} from './MenuList/AdminMenuList';

const {Sider} = Layout;

const SideMenu = ({auth, collapsed, onClick}) => {

  const location = useLocation();
  const {user} = auth;

  const [currentPath, setCurrentPath] = useState(
    location.pathname === ROUTE_BASE_ROOT || location.pathname === ''
      ? ROUTE_BASE_ROOT
      : location.pathname.split(ROUTE_BASE_ROOT)[1],
  );

  useEffect(() => {
    if (location) {
      if (currentPath !== location.pathname) {
        setCurrentPath(location.pathname);
      }
    }
  }, [location, currentPath]);

  const topSideMenuList =
        user.role === UserRoleEnum.ADMIN
          ? AdminTopSideMenuList
          : user.role === UserRoleEnum.MANAGER
            ? ManagerMenuList
            : EmployeeMenuList;

  return (
    <Sider
      theme={'light'}
      trigger={null}
      collapsible
      collapsed={collapsed}>
      <Link to={ROUTE_HOME}>
        <Col className={'flex justify-center'}>
          <Image
            preview={false}
            width={'100%'}
            src={SuyashCLLCImg}
          />
        </Col>
      </Link>
      {
        collapsed
          ?
          (<MenuUnfoldOutlined className={'m-2 text-2xl flex justify-center hover:text-blue-600'} onClick={onClick} />)
          :
          (<MenuFoldOutlined className={'m-2 text-3xl flex justify-start hover:text-blue-600'} onClick={onClick} />)
      }
      <Menu
        mode={'inline'}
        defaultSelectedKeys={[currentPath]}
        items={[
          ...topSideMenuList.map(menu => MenuBuilder(menu)),
          ...AuthUtil.isAdmin(user?.role) ? AdminMenuGroup : [],
          ...LogoutMenuList,
        ]}
      />
    </Sider>
  );
};

SideMenu.propTypes = {
  auth: PropTypes.shape({
    loggedIn: PropTypes.bool,
    user: PropTypes.shape({
      role: PropTypes.string,
    }).isRequired,
  }),
  collapsed: PropTypes.bool,
  onClick: PropTypes.func,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(SideMenu);
