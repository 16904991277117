import {Space, Typography} from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import {selectAuth} from '@app/redux/selectors';
import {connect} from 'react-redux';

const {Text} = Typography;

const WelcomeHeader = ({auth}) => {
  return (
    <Space>
      <Typography className={'text-2xl text-white ml-4 w-full'}>
        Welcome, {auth?.user?.firstName} {auth?.user?.lastName}
        {
          auth?.user?.role && <Text className={'italic text-white ml-1'}>({auth?.user?.role})</Text>
        }
      </Typography>
    </Space>
  );
};

WelcomeHeader.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(WelcomeHeader);
