import {useInfiniteQuery, useQuery} from '@tanstack/react-query';
import {rqCreateRequest} from './rqHttpsUtils';
import {
  RQ_GET_ALL_EMPLOYEE_LIST_API,
  RQ_GET_EMPLOYEE_BY_ID_API,
  RQ_GET_EMPLOYEE_BY_ID_WITH_MANAGER_DETAILS_API,
  RQ_GET_EMPLOYEE_COUNT_BY_ROLE_AND_STATUS_LIST_API,
  RQ_GET_EMPLOYEE_LIST_BY_MANAGER_ID_API,
  RQ_GET_EMPLOYEE_LOOKUP_ALL_ACTIVE_SUPERVISOR_API,
  RQ_GET_MANAGER_AND_ADMIN_LIST_API,
  RQ_GET_PAGEABLE_ALL_EMPLOYEE_API,
  RQ_REGISTER_EMPLOYEE_ALL_FIELDS_API,
  RQ_UPDATE_EMPLOYEE_ALL_FIELDS_API,
  RQ_UPDATE_EMPLOYEE_PROFILE_FIELD_API,
} from './constants';

export const RQUseGetPageableAllEmployeeData = payload => {
  return useInfiniteQuery({
    queryKey: ['rq_get_pageable_all_employees', payload],
    queryFn: async ({queryKey, signal, pageParam = 0}) => {
      const {tokenData, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_PAGEABLE_ALL_EMPLOYEE_API,
        null,
        tokenData?.access_token,
        {pageParam},
        signal,
        'application/json',
        'application/x-ndjson',
        filter,
      );
    },
    initialPageParam: 0,
    gcTime: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
  });
};

export const RQUseGetEmployeeByIdData = payload => {
  return useQuery({
    queryKey: ['rq_get_employee_by_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {id, tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EMPLOYEE_BY_ID_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
      );
    },
    gcTime: 0,
  });
};

export const RQUseGetEmployeeByIdWithManagerDetailsData = payload => {
  return useQuery({
    queryKey: ['rq_get_employee_by_id_with_manager_details', payload],
    queryFn: async ({queryKey, signal}) => {
      const {id, tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EMPLOYEE_BY_ID_WITH_MANAGER_DETAILS_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
      );
    },
    gcTime: 0,
  });
};

export const RQUseGetEmployeeListByManagerIdData = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_employee_list_by_manager_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {id, tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EMPLOYEE_LIST_BY_MANAGER_ID_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
      );
    },
    gcTime: 2 * 60 * 1000, // 2 min
    ...options,
  });
};

export const RQPutEmployeeUpdateAllFields = async ({
  employee,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_EMPLOYEE_ALL_FIELDS_API,
    JSON.stringify({...employee}),
    tokenData?.access_token,
    {employeeId: employee.id},
    signal,
  );
};

export const RQPostEmployeeRegisterAllFields = async ({
  employee,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REGISTER_EMPLOYEE_ALL_FIELDS_API,
    JSON.stringify({...employee}),
    tokenData?.access_token,
    null,
    signal,
  );
};

export const RQPutUpdateEmployeeProfileField = async ({
  employee,
  tokenData,
  id,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_EMPLOYEE_PROFILE_FIELD_API,
    JSON.stringify({...employee}),
    tokenData?.access_token,
    {id},
    signal,
  );
};

export const RQUseGetAllActiveEmployeeListData = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_all_employee_list_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_ALL_EMPLOYEE_LIST_API,
        null,
        tokenData?.access_token,
        null,
        signal,
      );
    },
    gcTime: 2 * 60 * 1000, // 2 min
    ...options,
  });
};

export const RQUseGetManagerAndAdminListData = payload => {
  return useQuery({
    queryKey: ['rq_get_manager_and_admin_list_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_MANAGER_AND_ADMIN_LIST_API,
        null,
        tokenData?.access_token,
        null,
        signal,
      );
    },
    gcTime: 2 * 60 * 1000, // 2 min
  });
};
export const RQUseGetEmployeeLookupAllActiveSupervisor = payload => {
  return useQuery({
    queryKey: ['rq_get_employee_lookup_all_active_supervisor', payload],
    queryFn: async ({queryKey, signal}) => {
      const {tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EMPLOYEE_LOOKUP_ALL_ACTIVE_SUPERVISOR_API,
        null,
        tokenData?.access_token,
        null,
        signal,
      );
    },
    gcTime: 2 * 60 * 1000, // 2 min
  });
};

export const RQUseGetEmployeeCountByRoleAndStatus = payload => {
  return useQuery({
    queryKey: ['rq_get_employee_count_by_role_and_status', payload],
    queryFn: async ({queryKey, signal}) => {
      const {tokenData, id, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EMPLOYEE_COUNT_BY_ROLE_AND_STATUS_LIST_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
        'application/json',
        'application/json',
        filter,
      );
    },
    gcTime: 0,
  });
};
