import React from 'react';
import {Outlet} from 'react-router-dom';
import * as PropTypes from 'prop-types';
import {Layout} from 'antd';

const {Content} = Layout;

const ContentLayout = () => {
  return (
    <Content className={'p-2 bg-primary-50'}>
      <Outlet />
    </Content>
  );
};

ContentLayout.propTypes = {navigation: PropTypes.any};

export default ContentLayout;
