import React from 'react';
import {Space} from 'antd';
import {WelcomeHeader} from './WelcomeHeader';
import {ROUTE_NOTIFICATION} from '@app/navigations/RoutePathConstant';
import {NotificationBadge} from '../../../../components/common/NotificationBadge';
import {Link} from 'react-router-dom';

const HeaderLayout = () => {
  return (
    <Space
      direction={'horizontal'}
      className={'justify-between pr-6 h-16 bg-gradient-to-r from-primary via-primary-300 to-primary w-full'}
    >
      <WelcomeHeader />
      <Link type={'link'} to={ROUTE_NOTIFICATION}>
        <NotificationBadge />
      </Link>
    </Space>
  );
};

export default HeaderLayout;
