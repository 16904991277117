export const RQ_DOCUMENT_UPLOAD_API = {
  url: 'api/v1/document/upload',
  method: 'POST',
  auth: true,
};

export const RQ_DOCUMENT_VIEW_BY_ID_API = {
  url: 'api/v1/document/<%= id %>/view',
  method: 'GET',
  auth: true,
};

export const RQ_DOCUMENT_THUMBNAIL_BY_ID_API = {
  url: 'api/v1/document/<%= id %>/thumbnail',
  method: 'GET',
  auth: true,
};

export const RQ_DOCUMENT_LOOKUP_API  = {
  url:'api/v1/document/<%= id %>/lookup',
  method: 'GET',
  auth: true,
};
