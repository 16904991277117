import React from 'react';
import {MenuBuilder} from './../MenuBuilder';
import {
  ClockCircleOutlined,
  DashboardOutlined,
  FieldTimeOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  SettingFilled,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {Typography} from 'antd';
import {
  ROUTE_DASHBOARD,
  ROUTE_EXPENSE_REPORT,
  ROUTE_EXPENSE_REPORT_FOR_ADMIN,
  ROUTE_PROFILE,
  ROUTE_PROJECT_REPORT,
  ROUTE_SETTINGS,
  ROUTE_TIMESHEET,
  ROUTE_TIMESHEET_CONFIG,
  ROUTE_TIMESHEET_CONFIG_WITH_TAB_PROJECT,
  ROUTE_TIMESHEET_REVIEW_FOR_ADMIN,
  ROUTE_USERS,
} from '@app/navigations/RoutePathConstant';

export const AdminTopSideMenuList = [
  {key: ROUTE_DASHBOARD, path: ROUTE_DASHBOARD, label: 'Dashboard', icon: <DashboardOutlined />},
  {
    key: ROUTE_TIMESHEET,
    path: ROUTE_TIMESHEET_REVIEW_FOR_ADMIN,
    label: 'Timesheet',
    icon: <ClockCircleOutlined />,
  },
  {
    key: ROUTE_EXPENSE_REPORT,
    path: ROUTE_EXPENSE_REPORT_FOR_ADMIN,
    label: 'Expense Report',
    icon: <FileTextOutlined />,
  },
  {
    key: ROUTE_PROJECT_REPORT,
    path: ROUTE_PROJECT_REPORT,
    label: 'Project Report',
    icon: <FileProtectOutlined />,
  },
  {
    key: ROUTE_PROFILE,
    path: ROUTE_PROFILE,
    label: 'Profile',
    icon: <UserOutlined />,
  },
];

const adminSideMenuList = [
  {key: ROUTE_USERS, path: ROUTE_USERS, label: 'Users', icon: <TeamOutlined />},
  {
    key: ROUTE_TIMESHEET_CONFIG,
    path: ROUTE_TIMESHEET_CONFIG_WITH_TAB_PROJECT,
    label: 'Timesheet Config',
    icon: <FieldTimeOutlined />,
  },
  {key: ROUTE_SETTINGS, path: ROUTE_SETTINGS, label: 'Settings', icon: <SettingFilled />},
];

export const AdminMenuGroup = [
  {type: 'divider'},
  {
    key: 'grp_1',
    label: <Typography.Title level={5} underline>Administration</Typography.Title>,
    type: 'group',
    children: adminSideMenuList.map(menu => MenuBuilder(menu)),
  },
];
