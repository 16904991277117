import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_USERS, ROUTE_USERS_CREATE, ROUTE_USERS_EDIT} from '../../RoutePathConstant';

const Users = lazy(() =>
  import('../../../screens/Users')
    .then(module => ({default: module.Users})));

const EditUser = lazy(() =>
  import('../../../screens/Users')
    .then(module => ({default: module.EditUser})));

const CreateUser = lazy(() =>
  import('../../../screens/Users')
    .then(module => ({default: module.CreateUser})));

const UsersNavigation = [
  {
    path: ROUTE_USERS,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Users />
      </Suspense>
    ),
  },
  {
    path: ROUTE_USERS_EDIT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EditUser />
      </Suspense>
    ),
  },
  {
    path: ROUTE_USERS_CREATE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CreateUser />
      </Suspense>
    ),
  },
];

export default UsersNavigation;
