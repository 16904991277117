import CommonStatusEnum from './CommonStatusEnum';

const TimesheetStatusEnum = {
  IN_PROGRESS: CommonStatusEnum.IN_PROGRESS,
  SUBMITTED: CommonStatusEnum.SUBMITTED,
  SUBMITTED_BY_SYSTEM: CommonStatusEnum.SUBMITTED_BY_SYSTEM,
  APPROVED_BY_ADMIN: CommonStatusEnum.APPROVED_BY_ADMIN,
  REJECTED_BY_ADMIN: CommonStatusEnum.REJECTED_BY_ADMIN,
  UNLOCKED: CommonStatusEnum.UNLOCKED,
  APPROVED_BY_MANAGER: CommonStatusEnum.APPROVED_BY_MANAGER,
  REJECTED_BY_MANAGER: CommonStatusEnum.REJECTED_BY_MANAGER,
};

export default TimesheetStatusEnum;
