import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_PROFILE} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const Profile = lazy(() =>
  import('../../../screens/Profile')
    .then(module => ({default: module.Profile})));

const ProfileNavigation = [
  {
    path: ROUTE_PROFILE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Profile />
      </Suspense>
    ),
  },
];

export default ProfileNavigation;
