import {useInfiniteQuery, useQuery} from '@tanstack/react-query';
import {rqCreateRequest} from './rqHttpsUtils';
import {
  RQ_GET_PAGEABLE_ALL_TIMESHEET_BY_STATUS_API,
  RQ_GET_PAGEABLE_ALL_TIMESHEET_REJECTED_FOR_EMPLOYEE_API,
  RQ_GET_PAGEABLE_ALL_TIMESHEET_SUBMITTED_FOR_APPROVAL_FOR_ADMIN_API,
  RQ_GET_PAGEABLE_ALL_TIMESHEET_SUBMITTED_FOR_APPROVAL_FOR_MANAGER_API,
  RQ_GET_PAGEABLE_TIMESHEET_APPROVED_FOR_ADMIN_API,
  RQ_GET_PAGEABLE_TIMESHEET_APPROVED_FOR_EMPLOYEE_API,
  RQ_GET_PAGEABLE_TIMESHEET_APPROVED_FOR_MANAGER_API,
  RQ_GET_TIMESHEET_BY_ID_API,
  RQ_GET_TIMESHEET_BY_SEARCH_FOR_EMPLOYEE_API,
  RQ_GET_TIMESHEET_COUNT_BY_EMPLOYEE_ID_AND_STATUS_LIST_API,
  RQ_GET_TIMESHEET_COUNT_BY_MANAGER_ID_AND_STATUS_LIST_API,
  RQ_GET_TIMESHEET_COUNT_FOR_ADMIN_AND_STATUS_LIST_API,
  RQ_GET_TIMESHEET_EXPORT_TO_EXEL_API,
  RQ_GET_TIMESHEET_EXPORT_TO_QUICKBOOK_API,
  RQ_GET_TIMESHEET_PAGEABLE_BY_SEARCH_FOR_EMPLOYEE_API,
  RQ_REGISTER_TIMESHEET_WITH_START_DATE_API,
  RQ_UPDATE_EMPLOYEE_TIMESHEET_STATUS_AND_COMMENTS_API,
  RQ_UPDATE_MANAGER_TIMESHEET_STATUS_AND_COMMENTS_API,
  RQ_UPDATE_TIMESHEET_APPROVER_API,
  RQ_UPDATE_TIMESHEET_STATUS_AND_COMMENTS_API,
} from './constants';

export const RQUseGetPageableAllTimesheetByStatusData = payload => {
  return useInfiniteQuery({
    queryKey: ['rq_get_pageable_all_timesheet_by_status', payload],
    queryFn: async ({queryKey, signal, pageParam = 0}) => {
      const {tokenData, status} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_PAGEABLE_ALL_TIMESHEET_BY_STATUS_API,
        null,
        tokenData?.access_token,
        {status, pageParam},
        signal,
        'application/json',
        'application/x-ndjson',
      );
    },
    initialPageParam: 0,
    gcTime: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
  });
};

export const RQUseGetPageableAllTimesheetSubmittedForApprovalForAdminData = payload => {
  return useInfiniteQuery({
    queryKey: ['rq_get_pageable_all_timesheet_submitted_for_approval_for_admin', payload],
    queryFn: async ({queryKey, signal, pageParam = 0}) => {
      const {tokenData, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_PAGEABLE_ALL_TIMESHEET_SUBMITTED_FOR_APPROVAL_FOR_ADMIN_API,
        null,
        tokenData?.access_token,
        {pageParam},
        signal,
        'application/json',
        'application/x-ndjson',
        filter,
      );
    },
    initialPageParam: 0,
    gcTime: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
  });
};

export const RQUseGetPageableAllTimesheetSubmittedForApprovalForManagerData = payload => {
  return useInfiniteQuery({
    queryKey: ['rq_get_pageable_all_timesheet_submitted_for_approval_for_manager', payload],
    queryFn: async ({queryKey, signal, pageParam = 0}) => {
      const {id, tokenData, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_PAGEABLE_ALL_TIMESHEET_SUBMITTED_FOR_APPROVAL_FOR_MANAGER_API,
        null,
        tokenData?.access_token,
        {id, pageParam},
        signal,
        'application/json',
        'application/x-ndjson',
        filter,
      );
    },
    initialPageParam: 0,
    gcTime: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
  });
};

export const RQUseGetPageableAllTimesheetRejectedForEmployeeData = payload => {
  return useInfiniteQuery({
    queryKey: ['rq_get_pageable_all_timesheet_rejected_for_employee', payload],
    queryFn: async ({queryKey, signal, pageParam = 0}) => {
      const {id, tokenData, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_PAGEABLE_ALL_TIMESHEET_REJECTED_FOR_EMPLOYEE_API,
        null,
        tokenData?.access_token,
        {id, pageParam},
        signal,
        'application/json',
        'application/x-ndjson',
        filter,
      );
    },
    initialPageParam: 0,
    gcTime: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
  });
};

export const RQUseGetTimesheetByIdData = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_timesheet_by_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {id, tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_TIMESHEET_BY_ID_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
      );
    },
    gcTime: 0,
    ...options,
  });
};

export const RQUseGetTimesheetBySearchForEmployee = payload => {
  return useQuery({
    queryKey: ['rq_get_timesheet_by_search_for_employee', payload],
    queryFn: async ({queryKey, signal}) => {
      const {filter, tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_TIMESHEET_BY_SEARCH_FOR_EMPLOYEE_API,
        null,
        tokenData?.access_token,
        {id: filter?.userId},
        signal,
        'application/json',
        'application/json',
        filter,
      );
    },
    gcTime: 0,
  });
};

export const RQUseGetTimesheetPageableBySearchForEmployee = payload => {
  return useInfiniteQuery({
    queryKey: ['rq_get_timesheet_pageable_by_search_for_employee', payload],
    queryFn: async ({queryKey, signal, pageParam = 0}) => {
      const {filter, tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_TIMESHEET_PAGEABLE_BY_SEARCH_FOR_EMPLOYEE_API,
        null,
        tokenData?.access_token,
        {id: filter?.userId, pageParam},
        signal,
        'application/json',
        'application/json',
        filter,
      );
    },
    initialPageParam: 0,
    gcTime: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
  });
};

export const RQPutTimesheetUpdateStatusAndComments = async ({
  timesheetToUpdate,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_TIMESHEET_STATUS_AND_COMMENTS_API,
    JSON.stringify({...timesheetToUpdate}),
    tokenData?.access_token,
    {id: timesheetToUpdate.id},
    signal,
  );
};

export const RQPutManagerTimesheetUpdateStatusAndComments = async ({
  timesheetToUpdate,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_MANAGER_TIMESHEET_STATUS_AND_COMMENTS_API,
    JSON.stringify({...timesheetToUpdate}),
    tokenData?.access_token,
    {id: timesheetToUpdate.id},
    signal,
  );
};

export const RQPutEmployeeTimesheetUpdateStatusAndComments = async ({
  timesheetToUpdate,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_EMPLOYEE_TIMESHEET_STATUS_AND_COMMENTS_API,
    JSON.stringify({...timesheetToUpdate}),
    tokenData?.access_token,
    {id: timesheetToUpdate.id},
    signal,
  );
};

export const RQPostRegisterTimesheetWithStartDate = async ({
  timesheetData,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REGISTER_TIMESHEET_WITH_START_DATE_API,
    JSON.stringify({...timesheetData}),
    tokenData?.access_token,
    null,
    signal,
  );
};

export const RQPutTimesheetUpdateApprover = async ({
  timesheetToUpdate,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_TIMESHEET_APPROVER_API,
    JSON.stringify({...timesheetToUpdate}),
    tokenData?.access_token,
    {id: timesheetToUpdate.id},
    signal,
  );
};

export const RQUseGetPageableTimesheetApprovedForEmployeeData = payload => {
  return useInfiniteQuery({
    queryKey: ['rq_get_pageable_timesheet_approved_for_employee', payload],
    queryFn: async ({queryKey, signal, pageParam = 0}) => {
      const {id, tokenData, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_PAGEABLE_TIMESHEET_APPROVED_FOR_EMPLOYEE_API,
        null,
        tokenData?.access_token,
        {id, pageParam},
        signal,
        'application/json',
        'application/x-ndjson',
        filter,
      );
    },
    initialPageParam: 0,
    gcTime: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
  });
};

export const RQUseGetPageableTimesheetApprovedForManagerData = payload => {
  return useInfiniteQuery({
    queryKey: ['rq_get_pageable_timesheet_approved_for_manager', payload],
    queryFn: async ({queryKey, signal, pageParam = 0}) => {
      const {id, tokenData, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_PAGEABLE_TIMESHEET_APPROVED_FOR_MANAGER_API,
        null,
        tokenData?.access_token,
        {id, pageParam},
        signal,
        'application/json',
        'application/x-ndjson',
        filter,
      );
    },
    initialPageParam: 0,
    gcTime: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
  });
};

export const RQUseGetPageableTimesheetApprovedForAdminData = payload => {
  return useInfiniteQuery({
    queryKey: ['rq_get_pageable_timesheet_approved_for_admin', payload],
    queryFn: async ({queryKey, signal, pageParam = 0}) => {
      const {tokenData, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_PAGEABLE_TIMESHEET_APPROVED_FOR_ADMIN_API,
        null,
        tokenData?.access_token,
        {pageParam},
        signal,
        'application/json',
        'application/x-ndjson',
        filter,
      );
    },
    initialPageParam: 0,
    gcTime: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
  });
};

export const RQUseGetTimesheetCountByEmployeeIdAndStatus = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_timesheet_count_by_employee_id_and_status', payload],
    queryFn: async ({queryKey, signal}) => {
      const {tokenData, id, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_TIMESHEET_COUNT_BY_EMPLOYEE_ID_AND_STATUS_LIST_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
        'application/json',
        'application/json',
        filter,
      );
    },
    gcTime: 0,
    ...options,
  },
  );
};

export const RQUseGetTimesheetCountByManagerIdAndStatus = payload => {
  return useQuery({
    queryKey: ['rq_get_timesheet_count_by_manager_id_and_status', payload],
    queryFn: async ({queryKey, signal}) => {
      const {tokenData, id, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_TIMESHEET_COUNT_BY_MANAGER_ID_AND_STATUS_LIST_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
        'application/json',
        'application/json',
        filter,
      );
    },
    gcTime: 0,
  });
};

export const RQUseGetTimesheetCountForAdminAndStatus = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_timesheet_count_for_admin_and_status', payload],
    queryFn: async ({queryKey, signal}) => {
      const {tokenData, id, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_TIMESHEET_COUNT_FOR_ADMIN_AND_STATUS_LIST_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
        'application/json',
        'application/json',
        filter,
      );
    },
    gcTime: 0,
    ...options,
  });
};

export const RQUseGetTimesheetExportToExcel = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_timesheet_export_to_excel', payload],
    queryFn: async ({queryKey, signal}) => {
      const {tokenData, id} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_TIMESHEET_EXPORT_TO_EXEL_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
        'application/json',
        'application/json',
      );
    },
    gcTime: 0,
    ...options,
  });
};

export const RQUseGetTimesheetExportToQuickbook = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_timesheet_export_to_quickbook', payload],
    queryFn: async ({queryKey, signal}) => {
      const {tokenData, id} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_TIMESHEET_EXPORT_TO_QUICKBOOK_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
        'application/json',
        'application/json',
      );
    },
    gcTime: 0,
    ...options,
  });
};
