import {useInfiniteQuery, useQuery} from '@tanstack/react-query';
import {rqCreateRequest} from './rqHttpsUtils';
import {
  RQ_GET_NOTIFICATION_IN_APP_COUNT_BY_STATUS_LIST_API,
  RQ_GET_NOTIFICATION_IN_APP_PAGEABLE_BY_SEARCH_API,
  RQ_UPDATE_NOTIFICATION_IN_APP_STATUS_API,
} from './constants';

export const RQUseGetNotificationInAppCountByStatus = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_notification_in_app_count_by_status', payload],
    queryFn: async ({queryKey, signal}) => {
      const {tokenData, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_NOTIFICATION_IN_APP_COUNT_BY_STATUS_LIST_API,
        null,
        tokenData?.access_token,
        null,
        signal,
        'application/json',
        'application/json',
        filter,
      );
    },
    gcTime: 0,
    ...options,
  });
};

export const RQUseGetNotificationInAppPageableBySearch = (payload, options = {}) => {
  return useInfiniteQuery({
    queryKey: ['rq_get_notification_in_app_pageable_by_search', payload],
    queryFn: async ({queryKey, signal, pageParam = 0}) => {
      const {tokenData, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_NOTIFICATION_IN_APP_PAGEABLE_BY_SEARCH_API,
        null,
        tokenData?.access_token,
        {pageParam},
        signal,
        'application/json',
        'application/json',
        filter,
      );
    },
    initialPageParam: 0,
    refetchInterval: 30 * 1000,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
    ...options,
  },
  );
};

export const RQPutNotificationInAppStatusUpdate = async ({id, status, tokenData, signal}) => {
  return rqCreateRequest(
    RQ_UPDATE_NOTIFICATION_IN_APP_STATUS_API,
    JSON.stringify({status}),
    tokenData?.access_token,
    {id},
    signal,
  );
};
