// base
export const ROUTE_BASE_ROOT = '/'; // *WARN: DO NOT change this
export const ROUTE_BASE_NAME = '/syc'; // *WARN: DO NOT change this
export const ROUTE_WILDCARD = '*';

export const ROUTE_LOGIN = 'login';
export const ROUTE_FORGOT_PASSWORD = 'forgotpassword';
export const ROUTE_HOME = 'home';

// -- USERS
// dashboard
export const ROUTE_DASHBOARD = 'dashboard';
export const ROUTE_PROFILE = 'profile';

// timesheet
export const ROUTE_TIMESHEET = 'timesheet';
export const ROUTE_TIMESHEET_WITH_TAB_PARAM = 'timesheet/:selectedtab';

// timesheet - admin
export const ROUTE_TIMESHEET_SUBMITTED_FOR_APPROVAL_FOR_ADMIN = 'timesheet/submittedforapprovalforadmin';
export const ROUTE_TIMESHEET_SUBMITTED_FOR_APPROVAL_FOR_ADMIN_EDIT
    = 'timesheet/submittedforapprovalforadmin/:timesheetId/edit';
export const ROUTE_TIMESHEET_REVIEW_FOR_ADMIN = 'timesheet/reviewforadmin';
export const ROUTE_TIMESHEET_APPROVED_FOR_ADMIN = 'timesheet/approvedforadmin';
export const ROUTE_TIMESHEET_APPROVED_FOR_ADMIN_EDIT = 'timesheet/approvedforadmin/:timesheetId/edit';
export const ROUTE_TIMESHEET_IN_PROGRESS_FOR_ADMIN = 'timesheet/inprogressforadmin';
export const ROUTE_TIMESHEET_IN_PROGRESS_FOR_ADMIN_EDIT = 'timesheet/inprogressforadmin/:timesheetId/edit';
export const ROUTE_TIMESHEET_REJECTED_FOR_ADMIN = 'timesheet/rejectedforadmin';
export const ROUTE_TIMESHEET_REJECTED_FOR_ADMIN_EDIT = 'timesheet/rejectedforadmin/:timesheetId/edit';

// timesheet - manager
export const ROUTE_TIMESHEET_SUBMITTED_FOR_APPROVAL_FOR_MANAGER = 'timesheet/submittedforapprovalformanager';
export const ROUTE_TIMESHEET_SUBMITTED_FOR_APPROVAL_FOR_MANAGER_EDIT
    = 'timesheet/submittedforapprovalformanager/:timesheetId/edit';
export const ROUTE_TIMESHEET_REVIEW_FOR_MANAGER = 'timesheet/reviewformanager';
export const ROUTE_TIMESHEET_APPROVED_FOR_MANAGER = 'timesheet/approvedformanager';
export const ROUTE_TIMESHEET_APPROVED_FOR_MANAGER_EDIT = 'timesheet/approvedformanager/:timesheetId/edit';

// timesheet - employee
export const ROUTE_TIMESHEET_REVIEW_FOR_EMPLOYEE = 'timesheet/reviewforemployee';
export const ROUTE_TIMESHEET_REJECTED_EDIT = 'timesheet/rejected/:timesheetId/edit';
export const ROUTE_TIMESHEET_REJECTED = 'timesheet/rejected';
export const ROUTE_TIMESHEET_IN_PROGRESS_EDIT = 'timesheet/inprogress/:timesheetId/edit';
export const ROUTE_TIMESHEET_IN_PROGRESS = 'timesheet/inprogress';
export const ROUTE_TIMESHEET_APPROVED_FOR_EMPLOYEE = 'timesheet/approvedforemployee';
export const ROUTE_TIMESHEET_APPROVED_FOR_EMPLOYEE_EDIT = 'timesheet/approvedforemployee/:timesheetId/edit';

// -- ADMIN
// users
export const ROUTE_USERS = 'users';
export const ROUTE_USERS_EDIT = 'users/:userId/edit';
export const ROUTE_USERS_CREATE = 'users/createuser';

// timesheet config
export const ROUTE_TIMESHEET_CONFIG = 'timesheetconfig';
export const ROUTE_TIMESHEET_CONFIG_WITH_TAB_PARAM = 'timesheetconfig/:selectedtab';
export const ROUTE_TIMESHEET_CONFIG_WITH_TAB_JOB_CODE = 'timesheetconfig/jobcode';
export const ROUTE_TIMESHEET_CONFIG_WITH_TAB_COST_CODE = 'timesheetconfig/costcode';
export const ROUTE_TIMESHEET_CONFIG_JOB_CODE_EDIT = 'timesheetconfig/jobcode/:jobCodeId/edit';
export const ROUTE_TIMESHEET_CONFIG_JOB_CODE_CREATE = 'timesheetconfig/jobcode/createjobcode';
export const ROUTE_TIMESHEET_CONFIG_WITH_TAB_PROJECT = 'timesheetconfig/project';
export const ROUTE_TIMESHEET_CONFIG_PROJECT_CREATE = 'timesheetconfig/project/createproject';
export const ROUTE_TIMESHEET_CONFIG_PROJECT_EDIT = 'timesheetconfig/project/:projectCodeId/edit';

// expense report
export const ROUTE_EXPENSE_REPORT = 'expensereport';
export const ROUTE_EXPENSE_REPORT_WITH_TAB_PARAM = 'expensereport/:selectedtab';
export const ROUTE_EXPENSE_REPORT_WITH_TAB_PARAM_FOR_ADMIN = 'expensereport/foradmin/:selectedtab';
export const ROUTE_EXPENSE_REPORT_FOR_MANAGER = 'expensereport/formanager';
export const ROUTE_EXPENSE_REPORT_FOR_ADMIN = 'expensereport/foradmin';
export const ROUTE_EXPENSE_REPORT_REVIEW_FOR_MANAGER = 'expensereport/reviewformanager';
export const ROUTE_EXPENSE_REPORT_CREATE_FOR_MANAGER = 'expensereport/formanager/createexpensereport';
export const ROUTE_EXPENSE_REPORT_FOR_EMPLOYEE = 'expensereport/foremployee';
export const ROUTE_EXPENSE_REPORT_CREATE_FOR_EMPLOYEE = 'expensereport/foremployee/createexpensereport';
export const ROUTE_EXPENSE_REPORT_EDIT_WITH_SELECTED_TAB = 'expensereport/:selectedtab/:expenseReportId/edit';
export const ROUTE_EXPENSE_REPORT_EDIT_WITH_SELECTED_TAB_FOR_ADMIN = 'expensereport/foradmin/:selectedtab/:expenseReportId/edit';

// project report
export const ROUTE_PROJECT_REPORT = 'projectreport';
export const ROUTE_PROJECT_REPORT_CREATE = 'projectreport/createprojectreport';
export const ROUTE_PROJECT_REPORT_EDIT = 'projectreport/:id/edit';

// settings
export const ROUTE_SETTINGS = 'settings';

// notification
export const ROUTE_NOTIFICATION = 'notification';

// watch
export const ROUTE_WATCH = 'watch';
export const ROUTE_WATCH_WITH_TAB_PARAM = 'watch/:selectedtab';
export const ROUTE_WATCH_WITH_TAB_JOB_CODE = 'watch/jobcode';
