export const UPDATE_DATE_FORMAT_START = 'UPDATE_DATE_FORMAT_START';
export const UPDATE_DATE_FORMAT_SUCCESSFUL = 'UPDATE_DATE_FORMAT_SUCCESSFUL';
export const UPDATE_DATE_FORMAT_FAIL = 'UPDATE_DATE_FORMAT_FAIL';

export const UPDATE_TIMEZONE_START = 'UPDATE_TIMEZONE_START';
export const UPDATE_TIMEZONE_SUCCESSFUL = 'UPDATE_TIMEZONE_SUCCESSFUL';
export const UPDATE_TIMEZONE_FAIL = 'UPDATE_TIMEZONE_FAIL';

export const UPDATE_TIMESHEET_DELAY_IN_HOURS_START = 'UPDATE_TIMESHEET_DELAY_IN_HOURS_START';
export const UPDATE_TIMESHEET_DELAY_IN_HOURS_SUCCESSFUL = 'UPDATE_TIMESHEET_DELAY_IN_HOURS_SUCCESSFUL';
export const UPDATE_TIMESHEET_DELAY_IN_HOURS_FAIL = 'UPDATE_TIMESHEET_DELAY_IN_HOURS_FAIL';

export const RESET_SETTINGS_DATA = 'RESET_SETTINGS_DATA';

export const UPDATE_IRS_MILEAGE_RATE_SUCCESSFUL = 'UPDATE_IRS_MILEAGE_RATE_SUCCESSFUL';

export const UPDATE_IRS_MILEAGE_RATE_FAIL = 'UPDATE_IRS_MILEAGE_RATE_FAIL';

export const UPDATE_OTHER_EXPENSE_TYPE_SUCCESSFUL = 'UPDATE_OTHER_EXPENSE_TYPE_SUCCESSFUL';

export const UPDATE_OTHER_EXPENSE_TYPE_FAIL = 'UPDATE_OTHER_EXPENSE_TYPE_FAIL';

export const CREATE_EXPENSE_TYPE_SUCCESSFUL = 'CREATE_EXPENSE_TYPE_SUCCESSFUL';

export const CREATE_EXPENSE_TYPE_FAIL = 'CREATE_EXPENSE_TYPE_FAIL';
