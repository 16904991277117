import {rqCreateRequest} from './rqHttpsUtils';
import {RQ_USER_FORGOT_PASSWORD_API, RQ_USER_LOGIN_API} from './constants';

export const RQUserLoginAPI = ({email, password, role, signal}) => {
  return rqCreateRequest(
    RQ_USER_LOGIN_API,
    JSON.stringify({
      email: email,
      password: password,
    }),
    null,
    {role},
    signal,
  );
};

export const RQUserForgotPasswordAPI = ({email, role, signal}) => {
  return rqCreateRequest(
    RQ_USER_FORGOT_PASSWORD_API,
    JSON.stringify({
      email: email,
    }),
    null,
    {role},
    signal,
  );
};
