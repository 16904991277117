import dayjs from 'dayjs';

dayjs.extend(require('dayjs/plugin/weekOfYear'));

export const MMDDYYYY = 'MM/DD/YYYY';
const ddddMMDDYYYY = 'dddd, MM/DD/YYYY';

export const getWeekOfTheYear = (date, dateFormat = MMDDYYYY) => {
  return dayjs(date, dateFormat).week();
};

export const getWeekEndingSelectionDays = (noOfWeekEndingWeeks = 5, dateFormat = MMDDYYYY) => {
  let initialWeekEndingDay = dayjs().day(5);
  if (initialWeekEndingDay.isBefore(dayjs())) {
    initialWeekEndingDay = initialWeekEndingDay.add(7, 'day');
  }

  const weekEndingWeeks = [];
  for (let i = 0; i < noOfWeekEndingWeeks; i++) {
    const weekEndingDay = initialWeekEndingDay.subtract(i, 'week');
    weekEndingWeeks.push({
      displayOrder: i + 1,
      displayText: weekEndingDay.format(ddddMMDDYYYY),
      value: weekEndingDay.format(dateFormat),
      defaultValue: i === 0,
    });
  }

  return weekEndingWeeks;
};

export const getWeekEndingSelectionDayFromBeforeAndAfterCurrentWeek = (previousNoOfWeeks = 10, nextNoOfWeeks = 5, dateFormat = 'MM/DD/YYYY') => {
  let initialWeekEndingDay = dayjs().day(5);
  if (initialWeekEndingDay.isBefore(dayjs())) {
    initialWeekEndingDay = initialWeekEndingDay.add(7, 'day');
  }

  const initialDate = initialWeekEndingDay.add(nextNoOfWeeks, 'week');

  const totalWeekEndingWeeks = [];
  for (let i = 0; i < (previousNoOfWeeks + nextNoOfWeeks); i++) {
    const weekEndingDay = initialDate.subtract(i, 'week');
    totalWeekEndingWeeks.push({
      displayOrder: i + 1,
      displayText: weekEndingDay.format(ddddMMDDYYYY),
      value: weekEndingDay.format(dateFormat),
      defaultValue: weekEndingDay.isSame(initialWeekEndingDay),
    });
  }
  return totalWeekEndingWeeks;
};
