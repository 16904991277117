import {rqCreateMultipartRequest, rqCreateRequest} from './rqHttpsUtils';
import {RQ_DOCUMENT_LOOKUP_API, RQ_DOCUMENT_UPLOAD_API} from './constants';
import {useQuery} from '@tanstack/react-query';

export const RQPostUploadDocument = async ({
  formData,
  tokenData,
  signal,
}) => {
  return rqCreateMultipartRequest(
    RQ_DOCUMENT_UPLOAD_API,
    {...formData},
    tokenData?.access_token,
    null,
    signal,
  );
};

export const RQUseGetDocumentInfo = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_document_lookup_api', payload],
    queryFn: async ({queryKey, signal}) => {
      const {id, tokenData} = queryKey[1];
      return rqCreateRequest(
        RQ_DOCUMENT_LOOKUP_API,
        null,
        tokenData?.access_token,
        {id: id},
        signal,
      );
    },
    ...options,
  });
};
